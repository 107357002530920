import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import Datatable from "react-bs-datatable";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";

const ProductList = (props) => {
  const notiRef = useRef();
  const header = [
    { title: "ID", prop: "id", sortable: true, filterable: true },
    {
      title: "Product Image",
      prop: "productImage",
      sortable: true,
      filterable: true,
    },
    {
      title: "Category Name",
      prop: "categoryName",
      sortable: true,
      filterable: true,
    },
    {
      title: "Product Name",
      prop: "productName",
      sortable: true,
      filterable: true,
    },

    { title: "Created", prop: "createdAt", sortable: true, filterable: true },
    { title: "Updated", prop: "updatedAt", sortable: true, filterable: true },
    { title: "Status", prop: "status", sortable: true, filterable: true },
    { title: "Action", prop: "action", sortable: true, filterable: true },
  ];

  const customLabels = {
    first: "<<",
    last: ">>",
    prev: "<",
    next: ">",
    show: "Display ",
    entries: " rows",
    noResults: "There is no data to be displayed",
  };
  const [data, setData] = useState([]);
  const [render, setRerender] = useState(false);

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: 3,
    };
    notiRef.current.notificationAlert(options);
  }

  function action(info) {
    console.log(info);
    api
      .post("/admin/product/deleteProduct", {
        productId: info.id,
        status: info.status === "active" ? "inactive" : "active",
      })
      .then((res) => {
        console.log(res.data.data);
        setRerender(!render);
        notify(res.data.data, "success");
      })
      .catch((err) => {
        console.log(err);
        notify(err, "danger");
      });
  }

  useEffect(() => {
    api
      .get("/admin/product/getProduct/" + null)
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data == "No Products Found") return setData([]);
        setData(
          res.data.data.map((data,i) => ({
            ...data,
            id:(
              <span style={{ textTransform: "capitalize" }}>
                {++i}
              </span>
            ),
            categoryName: (
              <span style={{ textTransform: "capitalize" }}>
                {data.categoryName}
              </span>
            ),
            productName: (
              <span style={{ textTransform: "capitalize" }}>
                {data.productName}
              </span>
            ),
            productImage: (
              <img src={process.env.REACT_APP_BASEDIR+data.productImage} width={90} height={90} />
            ),
            status:
              data.status == "active" ? (
                <button
                  className="btn btn-danger"
                  style={{ padding: "8px", paddingRight: "10px" }}
                  onClick={() => action(data)}
                >
                  Delete
                </button>
              ) : (
                <button
                  className="btn btn-secondary"
                  style={{ padding: "8px", paddingRight: "10px" }}
                  onClick={() => action(data)}
                >
                  <i className="i-action-undo"></i> <span>Undo</span>
                </button>
              ),
            createdAt: moment(data.createdAt).format(
              "MMM D ddd Y, h:mm:ss a"
            ),
            updatedAt: moment(data.updatedAt).format(
              "MMM D ddd Y, h:mm:ss a"
            ),
            action: (
              <button
                className="btn btn-secondary btn-icon bottom15 right15"
                style={{ padding: "8px", paddingRight: "10px" }}
                onClick={() =>
                  (window.location.href = "/updateProduct/" + data.id)
                }
              >
                <i className="i-pencil"></i> <span>Edit</span>
              </button>
            ),
            productImage: (
              <img src={process.env.REACT_APP_BASEDIR+data.productImage} width={50} height={50} />
            ),
          }))
        );
      })
      .catch((err) => console.log(err));
  }, [render, props.render]);

  return (
    <div className="col-12">
      <div className="notification-popup">
        <NotificationAlert ref={notiRef} />
      </div>
      <section className="box ">
        <header className="panel_header">
          <h2 className="title float-left">Product Lists</h2>
        </header>
        <div className="content-body">
          <div className="row">
            <div className="col-lg-12 dt-disp">
              <Datatable
                tableHeader={header}
                tableBody={data}
                keyName="userTable"
                tableClass="striped table-hover table-responsive"
                rowsPerPage={10}
                rowsPerPageOption={[5, 10, 15, 20, 30]}
                initialSort={{ prop: "id", isAscending: true }}
                //   onSort={onSortFunction}
                labels={customLabels}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductList;
