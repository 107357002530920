import React, { useEffect, useState, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import {} from "components";
import BannerList from "./BannerList";
import Cropper from "../Cropper";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";
import Select from "react-select";
import { customStyles } from "../../assets/css/CustomStyles";

const AddBanner = () => {
  const notiRef = useRef();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({
    image: "",
    bannerType: "normal",
    // bannerFor: "",
    bannerLink:"",
    productOrCategoryId: "",
    bannerText:"",
  });
  const bannertype = [
    { value: "offer", label: "offer", id: "bannerType" },
    { value: "normal", label: "normal", id: "bannerType" },
    { value: "festival", label: "festival", id: "bannerType" },
  ];
  const [productList, setProductList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [render, setRender] = useState(false);

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: 3,
    };

    notiRef.current.notificationAlert(options);
  }

  //----------- For Product Dropdown ------------------
  useEffect(() => {
    api.get("/admin/product/getProduct/" + null).then((res) => {
      if (Array.isArray(res.data.data))
        setProductList(
          res.data.data.map((data) => {
            return {
              label: data.productName,
              value: data.productName,
              id: "prodcate",
              finalId: data.id,
            };
          })
        );
    });
  }, []);

  //----------- For Category Dropdown ------------------
  useEffect(() => {
    api.get("/admin/product/getCategory").then((res) => {
      if (Array.isArray(res.data.data))
        setCategoryList(
          res.data.data.map((data) => {
            return {
              label: data.categoryName,
              value: data.categoryName,
              id: "prodcate",
              finalId: data.id,
            };
          })
        );
    });
  }, []);

  function handleChange(e) {
    if (e.label) {
      console.log("files", e);
      e.id == "prodcate"
        ? setData({ ...data, productOrCategoryId: 5 })
        : setData({ ...data, bannerType: "offer" });
    }
    // setData({...data, bannerType:e.value })
    else setData({ ...data, [e.target.name]: e.target.value });
  }

  function handleImageChange(e) {
    console.log("image", e);
    setData({ ...data, image: e });
  }

  function handleSubmit(e) {
    setLoad(true);
    e.preventDefault();
                 
    e.currentTarget.reset();
    console.log(data);
    const formdata = new FormData();
    Object.entries(data).map((data) => {
      formdata.append(data[0], data[1]);
    });

    api
      .post("/admin/shop/addBanners", formdata)
      .then((res) => {
        setLoad(false);
        setData("");
        
        notify(res.data.data, "success");
        setRender(!render);
       
      })
      .catch((err) => {
        setLoad(false);
        console.log(err);
      });
  }
  // console.log("CategoryList", data.bannerFor);

  return (
    <div>
      <div className="content">
        <div className="notification-popup">
          <NotificationAlert ref={notiRef} />
        </div>
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Banner</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title float-left">Add Banner</h2>
                </header>
                <div className="content-body">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <Form onSubmit={handleSubmit}>
                        <FormGroup>

                          <Label htmlFor="bannerImage">Banner Image</Label>
                          <br/>
                      <span style={{color:"red",fontWeight:"bold"}}> Dimensions : 	1500 × 653 </span> <br/>
                      <span  style={{color:"red",fontWeight:"bold"}}> Size: Max 10mb</span><br/>
                          <Cropper
                            imageStore={handleImageChange}
                            aspectRatio={3/1}
                            reset={render}
                          />
                        </FormGroup>

                        <FormGroup    style={{display:"none"}}>
                          <Label htmlFor="bannerType">Banner Type</Label>
                          <Select
                       
                            name="bannerType"
                            className="select2"
                            options={bannertype}
                            styles={customStyles}
                            onChange={handleChange}
                          />

                          
                        </FormGroup>
                        {/* <FormGroup>
                      <Label htmlFor="exampleEmail7">Banner Text</Label>
                      <Input
                        type="text"
                        name="bannerText"
                        id="exampleEmail7"
                        onChange={handleChange}
                        placeholder=""
                        required
                      />
                    </FormGroup> */}
                        {/* <FormGroup>
                      <Label htmlFor="exampleEmail7">Link</Label>
                      <Input
                        type="text"
                        name="bannerLink"
                        id="exampleEmail7"
                        onChange={handleChange}
                        placeholder=""
                        required
                      />
                    </FormGroup> */}



                        {/* <FormGroup>
                          <Label htmlFor="bannerFor">Banner For</Label>
                          <FormGroup tag="fieldset">
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="bannerFor"
                                  value="product"
                                  onChange={handleChange}
                                />{" "}
                                Product
                              </Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="bannerFor"
                                  value="category"
                                  onChange={handleChange}
                                />{" "}
                                Category
                              </Label>
                            </FormGroup>
                          </FormGroup>
                        </FormGroup> */}

                        {/* {data.bannerFor ? (
                          <FormGroup>
                            <Label htmlFor="exampleSelect3">Choose Type</Label>
                            <Select
                              name="bannerType"
                              className="select2"
                              options={
                                data.bannerFor == "product"
                                  ? productList
                                  : categoryList
                              }
                              styles={customStyles}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        ) : (
                          " "
                        )} */}

                        <FormGroup style={{ marginBottom: "0px" }}>
                          {load? <button type="submit" className="btn btn-primary" style={{color:"white",backgroundColor:"#247554"}} >
                            Loading...
                          </button>:  <button type="submit" className="btn btn-primary" style={{color:"white",backgroundColor:"#247554"
            }}>
                            Submit
                          </button>}
                         
                        
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <BannerList render={render} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddBanner;
