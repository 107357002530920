// import React from "react";
// import { Table, Row, Col } from "reactstrap";

// import {} from "components";

// import { Line, Bar } from "react-chartjs-2";

// import {
//   dashboardAllProductsChart,
//   dashboardAllProductsChart1,
//   dashboardAllProductsChart2,
//   dashboardAllProductsChart6,
//   dashboardAllProductsChart3,
// } from "variables/general/dashboard-charts-9.jsx";

// //import styles from 'jvectormap/jquery-jvectormap.css'
// import { VectorMap } from "react-jvectormap";

// var IMGDIR = process.env.REACT_APP_IMGDIR;

// class Dashboard9 extends React.Component {

//   render() {
//     return (
// <div>
//   <div className="content">
//     <Row>
//       <Col xs={12} md={12}>
//         <div className="page-title">
//           <div className="float-left">
//             <h1 className="title">Dashboard 9</h1>
//           </div>
//         </div>

//         <div className="row margin-0">
//           <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6">
//             <div className="db_box iconbox">
//               <div className="widdata">
//                 <i className="widicon i-user icon-lg icon-primary"></i>
//                 <h3 className="widtitle">Users</h3>
//                 <p className="widtag">32% increase</p>
//               </div>
//             </div>
//           </div>

//           <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6">
//             <div className="db_box iconbox bg-primary colored">
//               <div className="widdata">
//                 <i className="widicon i-basket icon-lg icon-white"></i>
//                 <h3 className="widtitle">Sales</h3>
//                 <p className="widtag">12% increase</p>
//               </div>
//             </div>
//           </div>
//           <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6">
//             <div className="db_box iconbox">
//               <div className="widdata">
//                 <i className="widicon i-badge icon-lg icon-primary"></i>
//                 <h3 className="widtitle">Awards</h3>
//                 <p className="widtag">23% increase</p>
//               </div>
//             </div>
//           </div>
//           <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6">
//             <div className="db_box iconbox bg-primary colored">
//               <div className="widdata">
//                 <i className="widicon i-wallet icon-lg icon-white"></i>
//                 <h3 className="widtitle">Earnings</h3>
//                 <p className="widtag">21% increase</p>
//               </div>
//             </div>
//           </div>

//           <div className="col-xl-2 d-xl-block d-lg-none d-md-none d-sm-block col-sm-4 col-6">
//             <div className="db_box iconbox">
//               <div className="widdata">
//                 <i className="widicon i-pencil icon-lg icon-primary"></i>
//                 <h3 className="widtitle">Posts</h3>
//                 <p className="widtag">30% increase</p>
//               </div>
//             </div>
//           </div>

//           <div className="col-xl-2 d-xl-block d-lg-none d-md-none d-sm-block col-lg-3 col-md-3 col-sm-4 col-6">
//             <div className="db_box iconbox bg-primary colored">
//               <div className="widdata">
//                 <i className="widicon i-share icon-lg icon-white"></i>
//                 <h3 className="widtitle">Shares</h3>
//                 <p className="widtag">20% increase</p>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="row margin-0">
//           <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
//             <div className="db_box graph_widget">
//               <div className="float-left">
//                 <div
//                   className="chart-area"
//                   style={{ height: 60 + "px", maxWidth: 80 + "px" }}
//                 >
//                   <Line
//                     data={dashboardAllProductsChart.data}
//                     options={dashboardAllProductsChart.options}
//                   />
//                 </div>
//               </div>
//               <div className="widdata float-left">
//                 <h2 className="widtitle">4,342</h2>
//                 <p className="widtag">Downloads today</p>
//               </div>
//             </div>
//           </div>

//           <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
//             <div className="db_box graph_widget">
//               <div className="float-left">
//                 <div
//                   className="chart-area"
//                   style={{ height: 60 + "px", maxWidth: 80 + "px" }}
//                 >
//                   <Line
//                     data={dashboardAllProductsChart1.data}
//                     options={dashboardAllProductsChart1.options}
//                   />
//                 </div>
//               </div>
//               <div className="widdata float-left">
//                 <h2 className="widtitle">5,643</h2>
//                 <p className="widtag">Shares today</p>
//               </div>
//             </div>
//           </div>

//           <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
//             <div className="db_box graph_widget">
//               <div className="float-left">
//                 <div
//                   className="chart-area"
//                   style={{ height: 60 + "px", maxWidth: 80 + "px" }}
//                 >
//                   <Line
//                     data={dashboardAllProductsChart2.data}
//                     options={dashboardAllProductsChart2.options}
//                   />
//                 </div>
//               </div>
//               <div className="widdata float-left">
//                 <h2 className="widtitle">7,332</h2>
//                 <p className="widtag">Posts today</p>
//               </div>
//             </div>
//           </div>

//           <div className="col-xl-3 col-lg-4 col-md-4 d-xl-block d-lg-none d-md-none d-sm-block col-sm-6 col-12">
//             <div className="db_box graph_widget">
//               <div className="float-left">
//                 <div
//                   className="chart-area"
//                   style={{ height: 60 + "px", maxWidth: 80 + "px" }}
//                 >
//                   <Line
//                     data={dashboardAllProductsChart6.data}
//                     options={dashboardAllProductsChart6.options}
//                   />
//                 </div>
//               </div>
//               <div className="widdata float-left">
//                 <h2 className="widtitle">3,433</h2>
//                 <p className="widtag">Logins today</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </Col>
//     </Row>
//   </div>
// </div>
//     );
//   }
// }

// export default Dashboard9;

import React from "react";
import {  Row, Col } from "reactstrap";

import {} from "components";

import { Line, Bar } from "react-chartjs-2";

import {
  dashboardAllProductsChart,
  dashboardAllProductsChart1,
  dashboardAllProductsChart2,
  dashboardAllProductsChart6,
  dashboardAllProductsChart3,
} from "variables/general/dashboard-charts-9.jsx";

//import styles from 'jvectormap/jquery-jvectormap.css'
import { VectorMap } from "react-jvectormap";
import { useEffect } from "react";
import api from "../../../Axios";
import { useState } from "react";


const Dashboard9 = () => {

  const [data, setData] = useState({
    activeCustomer:0,
    terminatedCustomer:0,
    inactiveCustomer:0,
    category:0,
    product:0,
    orders:0
  })
  useEffect(()=>{
    api.get("/admin/shop/dashboard").then(res=>{
      // console.log(res.data);
      setData(res.data.data)
    })
    .catch(err=>{
      console.log(err);
    })

    // api.get("/admin/auth/session").then(res=>{
    //   // console.log(res.data);
    //   setData(res.data.data)
    // })
    // .catch(err=>{
    //   console.log(err);
    // })
  },[])
  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Dashboard</h1>
              </div>
            </div>

            <div className="row margin-0">
              <div className="col-xl-4 col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="db_box iconbox">
                  <div className="widdata">
                    <i className="widicon i-user-following icon-lg icon-primary"></i>
                    <h3 className="widtitle" style={{whiteSpace:'pre-line'}}>Active Customers</h3>
                    <p className="widtag">{data.activeCustomer}</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="db_box iconbox  ">
                  <div className="widdata">
                    <i className="widicon i-user-following icon-lg icon-white"></i>
                    <h3 className="widtitle" style={{whiteSpace:'pre-line'}}>In-Active Customers</h3>
                    <p className="widtag">{data.inactiveCustomer}</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="db_box iconbox">
                  <div className="widdata">
                    <i className="widicon i-user-unfollow icon-lg icon-primary"></i>
                    <h3 className="widtitle" style={{whiteSpace:'pre-line'}}>Terminated Customers</h3>
                    <p className="widtag">{data.terminatedCustomer}</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="db_box iconbox ">
                  <div className="widdata">
                    <i className="widicon i-grid icon-lg icon-white"></i>
                    <h3 className="widtitle">Categories</h3>
                    <p className="widtag">{data.category}</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="db_box iconbox">
                  <div className="widdata">
                    <i className="widicon i-book-open icon-lg icon-primary"></i>
                    <h3 className="widtitle">Products</h3>
                    <p className="widtag">{data.product}</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="db_box iconbox ">
                  <div className="widdata">
                    <i className="widicon i-basket icon-lg icon-white"></i>
                    <h3 className="widtitle">Orders</h3>
                    <p className="widtag">{data.orders}</p>
                  </div>
                </div>
              </div>

            </div>

            
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard9;
