import Dashboard9 from "views/general/Dashboard/Dashboard9.jsx";

import FontAwesome from "views/general/Icons/FontAwesome.jsx";
import SimpleLineIcons from "views/general/Icons/SimpleLineIcons.jsx";

/*import UIDatagridsort from 'views/general/Tables/Datagridsort.jsx';*/
import Register from "views/general/Pages/Register.jsx";
import LockScreen from "views/general/Pages/LockScreen.jsx";

import Page403 from "views/general/Pages/403.jsx";
import Page404 from "views/general/Pages/404.jsx";
import Page405 from "views/general/Pages/405.jsx";
import Page408 from "views/general/Pages/408.jsx";
import Page500 from "views/general/Pages/500.jsx";
import Page503 from "views/general/Pages/503.jsx";
import Offline from "views/general/Pages/Offline.jsx";

import Invoice from "views/general/Invoice/Invoice.jsx";

import Category from "views/Category";
import UpdateCategory from "../views/Category/UpdateCategory";
import AddProduct from "views/Products/AddProduct.jsx";
import UpdateProduct from "../views/Products/UpdateProduct";
import AddVariant from "../views/Products/AddVariant";
import UpdateVariant from "../views/Products/UpdateVariant";
import AddBanner from "../views/Banner/AddBanner";
// import UpdateCategory from '../views/UpdateCategory';
import CustomerList from "../views/CustomerList";
import CustomerDetails from "../views/CustomerDetails";
import Orders from "../views/Orders/Orders";
import ViewOrder from "../views/Orders/ViewOrder";
import StockList from "../views/Stock/StockList";
import FaqAdd from "../views/FAQ/FaqAdd.js";
import VariantList from "../views/Products/VariantList";
import UpdateStock from "../views/Stock/UpdateStock";
import AdminProfile from "../views/AdminProfile";
import SessionList from "../views/Session/SessionList";
import Welcome from "../views/Welcome";
import BlogAdd from "../views/Blog/BlogAdd";
import EnqueriesList from "../views/EnqueriesList";
import ReturnOrders from "../views/Returns/ReturnOrders";
import ViewReturns from "../views/Returns/ViewReturns";
import EnqueriesListTest from "../views/EnqueriesListTest";
import ViewCancelOrders from "../views/Orders/ViewCancelOrders.js";
import CancelOrders from "../views/Orders/CancelOrders";

//import {menuStyle} from 'variables/settings/blog.jsx';

var BASEDIR = process.env.REACT_APP_BASEDIR;

var dashRoutes = [
  // component: AddProductVariant
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "speedometer",
    component: Dashboard9,
  },
  {
    path: "/customer-list",
    name: "Customer",
    icon: "people",
    component: CustomerList,
  },
  { path: "/customer-details/:id", component: CustomerDetails, type: "child" },
  { path: "/category", name: "Category", icon: "grid", component: Category },
  { path: "/updateCategory/:id", component: UpdateCategory, type: "child" },
  {
    path: "/product",
    name: "Product",
    icon: "book-open",
    component: AddProduct,
  },
  { path: "/updateProduct/:id", component: UpdateProduct, type: "child" },
  {
    path: "#",
    name: "Product Variant",
    icon: "layers",
    type: "dropdown",
    parentid: "components",
    child: [
      { path: "/addVariant", name: "Add Variant" },
      { path: "/variantList", name: "Variant Lists" },
    ],
  },
  { path: "/addVariant", component: AddVariant, type: "child" },
  { path: "/variantList", component: VariantList, type: "child" },
  { path: "/updateVariant/:id", component: UpdateVariant, type: "child" },

  { path: "/stock", name: "Stock", icon: "calendar", component: StockList },
  // {
  //   path: "/blogAdd",
  //   name: "Blog",
  //   icon: "book-open",
  //   component: BlogAdd,
  // },
  { path: "/banner", name: "Banner", icon: "picture", component: AddBanner },
  { path: "/orders", name: "Orders", icon: "basket", component: Orders },
  // {
  //   path: "/cancelorders",
  //   name: "Cancelled Orders",
  //   icon: "basket",
  //   component: CancelOrders,
  // },

  // { path: "/returns", name: "Return Orders", icon: "basket", component: ReturnOrders },
  {
    path: "/vieworders/:id",
    type: "child",
    component: ViewOrder,
  },
  {
    path: "/viewcancelorders/:id",
    type: "child",
    component: ViewCancelOrders,
  },
  {
    path: "/viewReturns/:id",
    type: "child",
    component: ViewReturns,
  },

  { path: "/stockUpdate/:id", component: UpdateStock, type: "child" },

  { path: "/adminProfile/1", component: AdminProfile, type: "child" },

  {
    path: "/FaqAdd",
    name: "FAQ",
    icon: "info",
    component: FaqAdd,
  },
  {
    path: "/sessionList",
    name: "Session List",
    icon: "book-open",
    component: SessionList,
  },
  // {
  //   path: "/enqueriesList",
  //   name: "Enqueries List",
  //   icon: "book-open",
  //   component: EnqueriesList,
  // },

  // {
  //   path: "/EnqueriesListTest",
  //   name: "Enqueries List dummy",
  //   icon: "book-open",
  //   component: EnqueriesListTest,
  // },
  {
    path: "/welcome",
    name: "Welcome Message",
    icon: "envelope",
    component: Welcome,
  },

  // {
  //   path: "#",
  //   name: "Access & Error Pages",
  //   icon: "key",
  //   type: "dropdown",
  //   parentid: "accesspages",
  //   child: [
  //     { path: BASEDIR + "/lockscreen", name: "Lock Screen" },
  //     { path: BASEDIR + "/register", name: "Registration" },
  //     { path: BASEDIR + "/403", name: "403" },
  //     { path: BASEDIR + "/404", name: "404" },
  //     { path: BASEDIR + "/405", name: "405" },
  //     { path: BASEDIR + "/408", name: "408" },
  //     { path: BASEDIR + "/500", name: "500" },
  //     { path: BASEDIR + "/503", name: "503" },
  //     { path: BASEDIR + "/offline", name: "Offline" },
  //   ],
  // },

  // { path: BASEDIR + "/lockscreen", component: LockScreen, type: "child" },
  // { path: BASEDIR + "/register", component: Register, type: "child" },
  // { path: BASEDIR + "/403", component: Page403, type: "child" },
  // { path: BASEDIR + "/404", component: Page404, type: "child" },
  // { path: BASEDIR + "/405", component: Page405, type: "child" },
  // { path: BASEDIR + "/408", component: Page408, type: "child" },
  // { path: BASEDIR + "/500", component: Page500, type: "child" },
  // { path: BASEDIR + "/503", component: Page503, type: "child" },
  // { path: BASEDIR + "/offline", component: Offline, type: "child" },

  // { path: BASEDIR + "/ui-invoice", component: Invoice, type: "child" },
  /*{ 
                            path: "#", name: "Support", icon: "question-circle", type: "dropdown", parentid:"",
                            child: [
                        
                            { path: BASEDIR+"/ui-faq", name: "FAQ"},
                            { path: BASEDIR+"/ui-tocify", name: "Documentation"},
                            ]
                        },
                            { path: BASEDIR+"/ui-faq", component: UITypography, type: "child"},
                            { path: BASEDIR+"/ui-tocify", component: UITypography, type: "child"},*/
  /*{ path: BASEDIR+"/ui-invoice-list", component: UITypography, type: "child"},
                            { path: BASEDIR+"/ui-invoice-add", component: UITypography, type: "child"},
                            { path: BASEDIR+"/ui-invoice-edit", component: UITypography, type: "child"},*/
  //{ path: "#", name: "User Interface", type: "navgroup"},

  // {
  //   path: "#",
  //   name: "Icon Sets",
  //   icon: "mustache",
  //   type: "dropdown",
  //   parentid: "icons",
  //   child: [

  //     { path: BASEDIR + "/ui-fontawesome", name: "Font Awesome" },
  //     { path: BASEDIR + "/ui-simplelineicons", name: "Simple Line Icons" },

  //   ],
  // },

  // { path: BASEDIR + "/ui-fontawesome", component: FontAwesome, type: "child" },
  // {
  //   path: BASEDIR + "/ui-simplelineicons",
  //   component: SimpleLineIcons,
  //   type: "child",
  // },

  {
    redirect: true,
    path: "",
    pathTo: "/dashboard",
    name: "Dashboard",
  },
];
export default dashRoutes;
