import React, { useState, useEffect, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import TagsInput from "react-tagsinput";
import "rc-color-picker/assets/index.css";
import ColorPicker from "rc-color-picker";
import Cropper from "../Cropper";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";
import { useParams } from "react-router-dom";

const Variant = () => {
  const notiRef = useRef();
  const params = useParams();
  const variantId = params.id;

  const [data, setData] = useState({
    productId: "",
    productName: "",
    variantName: "",
    variantImage: [],
    altTags: [],
    variantColor: "#000",
    actualPrice: 0,
    discountPrice: 0,
    tax: "",
  });

  const [submitRender, setSubmitRender] = useState(false);
  const [render, setRender] = useState(false);
  const [loading, setLoading] = useState(true);

  const parse = (data) => {
    try {
      return Array.isArray(JSON.parse(data)) ? JSON.parse(data) : [];
    } catch {
      return [];
    }
  };

  function notify(msg, type) {
    var options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: type === "danger" ? 5 : 3,
    };
    notiRef.current.notificationAlert(options);
    if (type === "success") {
      setTimeout(() => (window.location.href = "/variantList"), 1400);
    }
  }

  //---------- VARIANT DETAILS --------
  useEffect(() => {
    api
      .post("/admin/product/getVariant", { variantId })
      .then((res) => {
        setData({
          ...res.data.data,
          variantImage: parse(res.data.data.variantImage),
          altTags: parse(res.data.data.altTags),
        });
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  }, [variantId]);

  function setTags(e) {
    setData({ ...data, altTags: e });
    setRender(!render);
  }

  function handleChange(e) {
    setData({ ...data, [e.target.name]: e.target.value });
    setRender(!render);
  }

  function handleArrayChange(e, i) {
    if (e.target) {
      const updatedArray = [...data[e.target.name]];
      updatedArray[i] = e.target.value;
      setData({ ...data, [e.target.name]: updatedArray });
      setRender(!render);
    }
  }

  function handleImageChange(e, i) {
    const updatedVariantImage = [...data.variantImage];
    console.log(e);
    if (i < updatedVariantImage.length) {
      updatedVariantImage[i] = e;
    } else {
      updatedVariantImage.push(e);
    }
    setData({ ...data, variantImage: updatedVariantImage });
  }

  function handleSubmit(e) {
    e.preventDefault();
    const final = {
      variantImage: data.variantImage,
      variantId: variantId,
      productName: data.productName,
      variantName: data.variantName,
      tags: data.altTags,
      variantColor: data.variantColor,
      actualPrice: data.actualPrice,
      discountPrice: data.discountPrice,
    };
    api
      .post("/admin/product/updateVariant", final)
      .then((res) => {
        notify(res.data.data, "success");
        setSubmitRender(!submitRender);
        setData({
          ...data,
          productName: "",
          altTags: [],
          actualPrice: 0,
          discountPrice: 0,
          variantImage: [],
        });
      })
      .catch((err) => {
        notify(err.message, "danger");
      });
  }

  const addElement = () => {
    setData({ ...data, variantImage: [...data.variantImage, ""] });
  };

  const removeElement = () => {
    if (data.variantImage.length > 1) {
      const updatedVariantImage = [...data.variantImage];
      updatedVariantImage.pop();
      setData({ ...data, variantImage: updatedVariantImage });
    }
  };

  return (
    <div>
      <div className="notification-popup">
        <NotificationAlert ref={notiRef} />
      </div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Product Variant</h1>
              </div>
            </div>
            <div className="col-12">
              <section className="box">
                <header className="panel_header">
                  <h2 className="title float-left">Update Variant</h2>
                </header>
                {loading ? (
                  "Loading..."
                ) : (
                  <div className="content-body">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                        <Form onSubmit={handleSubmit}>
                          <FormGroup>
                            <Label htmlFor="variantName">Variant Name</Label>
                            <Input
                              type="text"
                              name="variantName"
                              value={data.variantName}
                              style={{ textTransform: "capitalize" }}
                              onChange={handleChange}
                              required
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label htmlFor="tags">Tags</Label>
                            <TagsInput
                              value={data.altTags}
                              name="tags"
                              onChange={setTags}
                              onlyUnique
                            />
                          </FormGroup>

                          {data.variantImage.map((image, i) => (
                            <React.Fragment key={i}>
                              {i > 0 && (
                                <>
                                  <br />
                                  <div
                                    className="mt-3"
                                    style={{ height: "10px" }}
                                  ></div>
                                </>
                              )}
                              <FormGroup>
                                <Label htmlFor="variantImage">
                                  Variant Image
                                </Label>
                                <br />
                                <span
                                  style={{ color: "red", fontWeight: "bold" }}
                                >
                                  Dimensions : 480 x 480
                                </span>{" "}
                                <br />
                                <span
                                  style={{ color: "red", fontWeight: "bold" }}
                                >
                                  Size: Max 3mb
                                </span>
                                <br />
                                <br />
                                {image && (
                                  <img
                                    src={`${process.env.REACT_APP_BASEDIR}${image}`}
                                    width={130}
                                    height={130}
                                    alt="img"
                                    style={{ marginBottom: "10px" }}
                                  />
                                )}
                                <Cropper
                                  imageStore={(i, e) => {
                                    handleImageChange(e, i, "image");
                                  }}
                                  index={i}
                                  reset={render}
                                  aspectRatio={1 / 1}
                                />
                              </FormGroup>
                            </React.Fragment>
                          ))}

                          <div style={{ margin: "10px 0px" }}>
                            <button
                              type="button"
                              onClick={addElement}
                              className="btn btn-primary"
                              style={{ color: "#fff" }}
                            >
                              Add Variant Image
                            </button>
                            {data.variantImage.length > 1 && (
                              <button
                                type="button"
                                onClick={removeElement}
                                className="btn btn-danger"
                              >
                                Remove Variant Image
                              </button>
                            )}
                          </div>

                          <FormGroup>
                            <Label htmlFor="actualPrice">Actual Price</Label>
                            <Input
                              type="text"
                              name="actualPrice"
                              value={data.actualPrice}
                              onChange={handleChange}
                              required
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label htmlFor="discountPrice">
                              Discount Price
                            </Label>
                            <Input
                              type="text"
                              name="discountPrice"
                              value={data.discountPrice}
                              onChange={handleChange}
                              required
                            />
                          </FormGroup>
                          <FormGroup style={{ marginBottom: "0px" }}>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              style={{
                                color: "#fff",
                                backgroundColor: "#247554",
                              }}
                            >
                              Submit
                            </button>
                          </FormGroup>
                        </Form>
                      </div>
                    </div>
                  </div>
                )}
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Variant;
